<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div>
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-310" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
            </el-button>
          </el-input>
        </div>
        <div class="flex flex-ac">
          <div class="ml-40 fc-666 fs-16 fc-6b9eff pointer" @click="$emit('getData')">
            <i class="icon iconfont icon-daochu mr-10 fs-18"></i>返回
          </div>
        </div>
      </div>
      <div class="retrieveDiv flex flex-ac flex-w">
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">用户名：</span>
          <el-input class="w-250" v-model="retrieveForm.userName" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">手机号：</span>
          <el-input class="w-250" v-model="retrieveForm.phone" clearable></el-input>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">是否测试：</span>
          <el-select class="w-150" v-model="retrieveForm.isTest" clearable placeholder="请选择">
            <el-option label="否" :value="0"></el-option>
            <el-option label="是" :value="1"></el-option>
          </el-select>
        </div>
        <div class="mr-30 flex flex-ac">
          <span class="fs-16 flex1 mr-20">创建时间：</span>
          <el-date-picker class="w-250" v-model="startEndDate" type="daterange" valueFormat="yyyy-MM-dd"
            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="handPickerChange">
          </el-date-picker>
        </div>
        <div>
          <el-button type="primary" @click="submitSearch">查询</el-button>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:image="e">
          <img :src="e.row.userImg" alt="" class="tableImg" />
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
import { mapState } from 'vuex'
export default {
  name: 'signInRecord',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
  },
  computed: {
    ...mapState('menu', {
      'couponsTypes': state => state.dictList ? state.dictList.coupons_type : [],  //优惠劵类型
    })
  },
  data () {
    return {
      retrieveForm: {
        userName: '',
        phone: '',
        isTest: '',
        startDate: '',
        endDate: '',
        useDate: '',
      },
      startEndDate: [], //时间区间
      tabLoading: false,
      butLoading: false,
      columns: [
        { type: 'index', label: '序号', width: '60', align: 'center', index: this.indexMethod },
        { columnType: 'custom', label: '用户头像', prop: 'image', align: 'left', width: '100' },
        { label: '用户姓名', prop: 'userName', align: 'left', 'show-overflow-tooltip': true },
        { label: '用户手机号', prop: 'phone', align: 'left', width: '120' },
        { label: '用户openId', prop: 'openId', align: 'left', 'show-overflow-tooltip': true },
        { label: '签到天数', prop: 'signDay', align: 'left', width: '80' },
        { label: '道具名称', prop: 'rewardName', align: 'left', width: '130' },
        { label: '道具数量', prop: 'propNum', align: 'left', width: '80' },
        { label: '创建时间', prop: 'createDate', align: 'left', width: '180' },
      ],
      tableData: [],
    }
  },
  mounted () {
    this.getTableData()
  },
  methods: {
    //新增列表
    addList () {
      this.dialogTitle = "新增"
      let tableData = this.tableData
      let signDay = 0
      if (tableData.length > 0) {
        signDay = tableData[tableData.length - 1].signDay + 1
      }
      this.ruleForm = {
        signDay: signDay,
        rewardName: '',
        rewardType: '',
        propNum: '',
        validDay: '',
        money: '',
        doorSill: '',
        couponsType: '',
      }
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate()
      })
    },
    //查询
    submitSearch () {
      this.pageInfo.pageNum = 1
      this.getTableData()
    },
    //时间变化
    handPickerChange (value) {
      console.log(value)
      if (value.length != 0) {
        this.retrieveForm.startDate = value[0]
        this.retrieveForm.endDate = value[1]
      }
    },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/signIn/findUserSignInList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          isTest: this.retrieveForm.isTest,//是否测试,0否1是
          startDate: this.retrieveForm.startDate,//开始时间
          endDate: this.retrieveForm.endDate,//结束时间
          userName: this.retrieveForm.userName,//用户名称 模糊查询
          phoen: this.retrieveForm.phoen,//用户手机号 模糊查询
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //跳转详情
    headerRowClick (row) {
      console.log('跳转详情')
      console.log(row)
      this.$refs['zero-activity-details'].init('user', row)
    },
  },
}
</script>

<style lang="less" scoped>
</style>